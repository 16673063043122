import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from 'layouts/zh'
import { EntertainState } from '../entertainment/index.zh'

const Social: React.FC = (props) => {
  const { socialYaml } = useStaticQuery(graphql`
    query {
      socialYaml {
        title
        description
        keywords
        saTitle
        banner_title
        banner_desc
        banner_btnHref
        banner_btnText
        banner_title_m
        banner_bg_mb
        banner_bg_pc
        banner_icon
        banner_icon_mb
        tabs {
          isActive
          name
          url
        }
        section_two_operationTitle
        section_two_operationTitleMb
        section_two_operationDesc
        section_two_mobileButtonText
        section_two_mobileButtonLink
        section_two_dataList {
          buttonHref
          buttonText
          dataTitle
          descList
          imgUrl
        }
        section_three_title
        section_three_desc
        section_three_cardList {
          cover
          desc
          title
        }
        panorama_title
        panorama_titleMb
        panorama_imgUrl
        panorama_desc
        panorama_btnText
        panorama_btnCode
        panorama_btnText_two
        panorama_btnText_two_url
        partner_buttonText
        partner_dataList {
          imgUrl
        }
        partner_qrcode
        product_dataList {
          btnHref
          btnText
          dataDsc
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        product_title
        product_titleMb
        digitization_btnHref
        digitization_btnText
        digitization_desc
        digitization_orderHref
        digitization_orderText
        digitization_title
        cargo_dataList {
          btnHref
          btnText
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        cargo_title
        cargo_titleMb
        customer_desc
        customer_title
        customer_titleMb
        consult_title
        consult_desc
        consult_dataList {
          iconImg
          iconText
        }
        consult_btnText
        consult_btnCode
      }
    }
  `)

  return (
    <Layout {...props}>
      <EntertainState data={socialYaml} />
    </Layout>
  )
}

export default Social
